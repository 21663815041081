// @flow

/* import * as style from "./style.module.scss";
 */
import { PageContent } from "@components";
import { LocalizedPreview } from "@containers";
import { Router } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";

/**
 * Preview body, could possibly be more generalized
 */
const Body = ({ entryId, token, environment, markets }: any) => {
    return (
        <LocalizedPreview
            id="footer-preview"
            entryId={entryId}
            token={token}
            environment={environment}
            markets={markets}
        >
            {(data, _, selectLocale) => (
                <PageContent
                    title={data.title}
                    publishedMarkets={markets}
                    slug={data.slug}
                    previewLocale={selectLocale}
                    enableScrollTop={data.enableScrollTop}
                    footer={data}
                    hideCategoryBar
                    hideCategoryBarMobile
                />
            )}
        </LocalizedPreview>
    );
};

const FooterPreview = ({ data }: *) => (
    <Router>
        <Body
            path="/preview/footer/:entryId/:token"
            environment={data.site.siteMetadata.environment}
            markets={data.markets.nodes}
        />
    </Router>
);

export default FooterPreview;

export const query = graphql`
    {
        site {
            siteMetadata {
                environment
            }
        }
        markets: allContentfulMarket {
            nodes {
                ...MarketGeneral
            }
        }
    }
`;
